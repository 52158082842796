import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';

import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { Observable } from 'rxjs/Rx';
import { ApiService } from '../../../api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user = {
    name: '1Folds',
    role: 'unknown',

  };
  private timer;


  currentTheme = 'default';

  // userMenu = [{ title: 'Profile', link: '/profile' }, { title: 'Log out', link: '/auth/logout' }];
  userMenu = [{ title: 'Profile', link: '/profile' }, { title: 'Log uit', link: '/auth/logout' }];

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private authService: NbAuthService,
              private apiservice: ApiService,
              private router: Router,) {
    
    this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {
        if (token.isValid()) {
          this.user.name = token.getPayload()['name'];
          this.user.role = token.getPayload()['role_name'];
        }
    });

  }

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;

    this.checkTTl()
    
    this.timer = Observable.interval(120*1000);
    this.timer.subscribe((t) => this.checkTTl());


    // cronjobs
    this.apiservice.runCronJobs().subscribe((data:any) => {
        
      }
    );

  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }


  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  error_counter = 0;
  private checkTTl() {
    this.apiservice.checkTTL().subscribe((data:any) => {
        if (data.data == 0) {
          this.error_counter = 0;
          this.router.navigate(['auth/logout']);
        }
      },
      (error:any) => {
        this.error_counter++;

        if (this.error_counter > 2) {
          this.error_counter = 0;
          this.router.navigate(['auth/logout']);
        }

        
      });
  }
}
