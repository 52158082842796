import { Component, OnDestroy, AfterViewInit, Output, Input, EventEmitter, ElementRef } from '@angular/core';
import { LocationStrategy } from '@angular/common';

@Component({
  selector: 'ngx-tiny-mce',
  template: '',
})
export class TinyMCEComponent implements OnDestroy, AfterViewInit {

  @Output() editorKeyup = new EventEmitter<any>();
  @Output() change = new EventEmitter<any>();
  @Input() disabled:boolean = false;
  @Input() height:number = 150;
  editor: any;

  constructor(
    private host: ElementRef,
    private locationStrategy: LocationStrategy,
  ) { }

  ngAfterViewInit() {
    tinymce.init({
      target: this.host.nativeElement,
      menubar: false,
      statusbar: false,
      readonly : this.disabled,
      plugins: ['link', 'paste', 'table', 'autoresize'],
      skin_url: `${this.locationStrategy.getBaseHref()}assets/skins/lightgray`,
      paste_data_images: true,
      autoresize_max_height: 1000,
      
      setup: editor => {
        this.editor = editor;
        editor.on('keyup', () => {
          this.editorKeyup.emit(editor.getContent());
        });
        editor.on('change', () => {
          this.change.emit(editor.getContent());
        });
      },
      
    });
  }

  ngOnDestroy() {
    tinymce.remove(this.editor);
  }
}