import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/Rx';



// **********************
// PRODUCTION OR DEV
const production = true; // <----------------------------
// API CONSTANTS
let apiUrl = production ? 'https://fs-ros-ocbbod6sdq-ew.a.run.app/fs-api/api' : 'http://localhost:8000/api';
let rootUrl = production ? 'https://fs-ros-ocbbod6sdq-ew.a.run.app/fs-api/' : 'http://localhost:8000/';
// **********************


export const dev = {
	auth: apiUrl,
	production: production,
}


@Injectable({
	providedIn: 'root',
})

export class ApiService {

	// BASICS
	baseUrl() {
		return rootUrl;
	}

 
	constructor(private http: HttpClient) {	}


	// GENERAL
	getVisitorsBasics() {
		return this.http.get(apiUrl+'/general/visitors/basics');
	}
	getVBBasics() {
		return this.http.get(apiUrl+'/general/voedselbank/basics');
	}
	getVVBasics() {
		return this.http.get(apiUrl+'/general/vv/basics');
	}
	getStatsBasics() {
		return this.http.get(apiUrl+'/general/stats/basics');
	}
	checkTTL() {
		return this.http.get(apiUrl+'/auth/checkTTL');
	}
	runCronJobs() {
		return this.http.get(apiUrl+'/general/cronjobs');
	}
	updateProfile(data) {
		return this.http.post(apiUrl+'/general/profile/update', data);
	}

	// FRONTDESK
	getFrontdeskVisitor(id) {
		return this.http.get(apiUrl+'/frontdesk/visitor/' + id);
	}
	getCheckins(date) {
		return this.http.get(apiUrl+'/frontdesk/checkins/' + date);
	}
	checkInVisitor(id) {
		return this.http.post(apiUrl+'/frontdesk/checkin/visitor',{id:id});
	}
	editCheckInNote(id, val) {
		return this.http.post(apiUrl+'/frontdesk/checkin/note',{id:id, val:val});
	}
	editCheckInStatus(id, val) {
		return this.http.post(apiUrl+'/frontdesk/checkin/status',{id:id, val:val});
	}
	cancelCheckIn(checkin_id) {
		return this.http.post(apiUrl+'/frontdesk/checkin/cancel',{checkin_id:checkin_id});
	}

	// VISITORS
	searchAllVisitors(search, status_all = 0, visitor_id = 0) {
		return this.http.get(apiUrl+'/visitors/search/' + search + '/' + status_all + '/' + visitor_id);
	}
	searchAllVisitorsWithPhonenumber(search, status_all = 0, visitor_id = 0) {
		return this.http.get(apiUrl+'/visitors/searchwithphone/' + search + '/' + status_all + '/' + visitor_id);
	}
	getVisitor(id) {
		return this.http.get(apiUrl+'/visitors/get/' + id);
	}
	getCheckinsById(id) {
		return this.http.get(apiUrl+'/visitors/checkins/' + id);
	}
	lastVisitorActions() {
		return this.http.get(apiUrl+'/visitors/last-actions');
	}
	createVisitor(data:any) {
		return this.http.post(apiUrl+'/visitors/create', {data:data});
	}
	createTempVisitor(data:any) {
		return this.http.post(apiUrl+'/visitors/create-temp', {data:data});
	}
	// updateVisitor(data:any) {
	// 	return this.http.post(apiUrl+'/visitors/update', {data:data});
	// }
	updateVisitorStatus(data:any) {
		return this.http.post(apiUrl+'/visitors/update-status', data);
	}
	updateImgVisitor(data:any) {
		return this.http.post(apiUrl+'/visitors/update-image', data);
	}
	updateVisitorField(data:any) {
		return this.http.post(apiUrl+'/visitors/update-field', data);
	}
	updateVisitorFieldVV(data:any) {
		return this.http.post(apiUrl+'/visitors/update-field-vv', data);
	}
	updateVisitorFieldShelter(data:any) {
		return this.http.post(apiUrl+'/visitors/update-field-shelter', data);
	}
	getTasks(visitor_id) {
		return this.http.get(apiUrl+'/visitors/tasks/get/' + visitor_id);
	}
	createTask(data) {
		return this.http.post(apiUrl+'/visitors/tasks/create', data);
	}
	updateTask(data) {
		return this.http.post(apiUrl+'/visitors/tasks/update', data);
	}
	getDashboardTasks() {
		return this.http.get(apiUrl+'/visitors/tasks/dashboard');
	}

	// DOCS
	getFiles(visitor_id) {
		return this.http.get(apiUrl+'/docs/get/' + visitor_id);
	}
	downloadFile(id) {
		return this.http.get(apiUrl+'/docs/download/' + id, { responseType: 'blob' });
	}
	viewFile(id) {
		return this.http.get(apiUrl+'/docs/view/' + id, { responseType: 'blob' });
	}
	
	deleteFile(data) {
		return this.http.post(apiUrl+'/docs/delete', data);
	}
	uploadFile(data, visitor_id) {
		return this.http.post(apiUrl+'/docs/upload/' + visitor_id, data);
	}

	// LOGBLOCKS
	getLogblocks(id, search:string, tags, type) {
		return this.http.get(apiUrl+'/logblocks/get/' + id + '/' + search + '/' + tags + '/' + type);
	}
	getLastLogblockReads() {
		return this.http.get(apiUrl+'/logblocks/getreads');
	}
	readLogblock(id) {
		return this.http.get(apiUrl+'/logblocks/read/'+ id);
	}
	addLogblocks(id) {
		return this.http.get(apiUrl+'/logblocks/add/' + id);
	}
	updateLogblocks(data) {
		return this.http.post(apiUrl+'/logblocks/update', {data});
	}
	updateLogblockTags(data) {
		return this.http.post(apiUrl+'/logblocks/update-tags', {data});
	}
	downloadLogblocks(id, search:string, tags, type) {
		return this.http.get(apiUrl+'/logblocks/get/' + id + '/' + search + '/' + tags + '/' + type + '/1', { responseType: 'blob' });
	}

	// CALENDAR
	getCalendarAppointments(date) {
		return this.http.get(apiUrl+'/calendar/appointments/' + date);
	}
	searchAllUsers(search, status_all = 0) {
		return this.http.get(apiUrl+'/calendar/search-users/' + search + '/' + status_all);
	}
	updateAppointment(data) {
		return this.http.post(apiUrl+'/calendar/appointment/update', data);
	}

	// INTAKE
	getAllQuestions() {
		return this.http.get(apiUrl+'/intake/questions');
	}
	getIntakeData(user_id: number) {
		return this.http.get(apiUrl+'/intake/data/' + user_id);
	}
	getIntakeDataVoedselbank(user_id: number) {
		return this.http.get(apiUrl+'/intake/data-voedselbank/' + user_id);
	}
	saveAnswer(question_id, visitor_id, val) {
		return this.http.post(apiUrl+'/intake/answers/save', {visitor_id:visitor_id, question_id:question_id, val: val});
	}
	updateIntakeDataVoedselbank(data: any) {
		return this.http.post(apiUrl+'/intake/voedselbank/update', data);
	}
	updateVisitorPartner(data: any) {
		return this.http.post(apiUrl+'/intake/partner/update', data);
	}
	updateVisitorChild(data: any) {
		return this.http.post(apiUrl+'/intake/child/update', data);
	}


	// SETTINGS - MU
	getAllUsers() {
		return this.http.get(apiUrl+'/settings/users');
	}
	getRoles() {
		return this.http.get(apiUrl+'/settings/roles');
	}
	getSmsSettings() {
		return this.http.get(apiUrl+'/settings/sms/all');
	}
	editSmsSettings(data) {
		return this.http.post(apiUrl+'/settings/sms/edit', data);
	}
	getEmailSettings() {
		return this.http.get(apiUrl+'/settings/email/all');
	}
	editEmailSettings(data) {
		return this.http.post(apiUrl+'/settings/email/edit', data);
	}
	postUsers(data) {
		return this.http.post(apiUrl+'/settings/users/edit', {data: data});
	}
	newUser(data) {
		return this.http.post(apiUrl+'/settings/users/new', {data: data});
	}
	changeIntakeQuestion(id, val, field) {
		return this.http.post(apiUrl+'/settings/intakequestion/edit', {id:id, val:val, field:field});
	}
	deleteIntakeQuestion(id) {
		return this.http.post(apiUrl+'/settings/intakequestion/delete', {id:id});
	}
	addIntakeQuestion(id) {
		return this.http.post(apiUrl+'/settings/intakequestion/add', {id:id});
	}
	getCalendarSettings() {
		return this.http.get(apiUrl+'/settings/calendar/get');
	}
	addCalendarTime(id_subject, day_number, hours, minutes) {
		return this.http.post(apiUrl+'/settings/calendar/time/add', {id_subject:id_subject, day_number:day_number, hours:hours, minutes:minutes});
	}
	editCalendarTime(id_time, val, hour_mintues) {
		return this.http.post(apiUrl+'/settings/calendar/time/edit', {id_time:id_time, val:val, hour_mintues:hour_mintues});
	}
	getTagsSettings() {
		return this.http.get(apiUrl+'/settings/tags/all');
	}
	updateTagsSettings(data) {
		return this.http.post(apiUrl+'/settings/tags/edit', data);
	}

	
	// MESSAGES
	getMessages(skip_end = 0, take = 20) {
		return this.http.get(apiUrl+'/messages/all/' + skip_end + '/' + take);
	}
	createMessage(data) {
		return this.http.post(apiUrl+'/messages/create', data);
	}
	updateMessage(data) {
		return this.http.post(apiUrl+'/messages/update', data);
	}

	// FORMS
	getForms() {
		return this.http.get(apiUrl+'/forms/all');
	}
	getForm(id) {
		return this.http.get(apiUrl+'/forms/get/' + id);
	}
	deleteForm(id) {
		return this.http.get(apiUrl+'/forms/delete/' + id);
	}
	updateForm(data) {
		return this.http.post(apiUrl+'/forms/update', data);
	}
	createVisitorForm(data) {
		return this.http.post(apiUrl+'/forms/visitor/create', data);
	}
	createForm(data) {
		return this.http.post(apiUrl+'/forms/create', {name: data});
	}
	updateVisitorForm(data) {
		return this.http.post(apiUrl+'/forms/visitor/update', data);
	}
	getVisitorForm(visitor_id, form_id) {
		return this.http.get(apiUrl+'/forms/visitor/get/'+visitor_id+'/'+form_id);
	}
	getVisitorsForms(visitor_id) {
		return this.http.get(apiUrl+'/forms/visitor/forms/' + visitor_id);
	}
	downloadVisitorForm(form_id) {
		return this.http.get(apiUrl+'/forms/visitor/download/' + form_id, { responseType: 'blob' });
	}
	deleteVisitorForm(id) {
		return this.http.get(apiUrl+'/forms/visitor/delete/' + id);
	}
	deleteRelation(data) {
		return this.http.post(apiUrl+'/visitors/relation/delete', data);
	}


	// STATS
	getVisitorsNew() {
		return this.http.get(apiUrl+'/stats/visitors/new');
	}
	getVisitorsAll() {
		return this.http.get(apiUrl+'/stats/visitors/all');
	}
	getStatsVoedselbank(data:any) {
		return this.http.get(apiUrl+'/stats/visitors/voedselbank?status='+data.status
										+'&uitgiftepunt='+data.uitgiftepunt
										+'&gender='+data.gender );
	}
	getStatsVoedselbankCsv(data:any) {
		return this.http.get(apiUrl+'/stats/visitors/voedselbank?status='+data.status
										+'&uitgiftepunt='+data.uitgiftepunt
										+'&gender='+data.gender
										+'&csv='+data.csv, { responseType: 'blob' } );
	}
	getStatsVisitorsChildren(data:any) {
		return this.http.get(apiUrl+'/stats/visitors/children?status='+data.status
										+'&uitgiftepunt='+data.uitgiftepunt
										+'&date_of_birth_month='+data.date_of_birth_month );
	}
	getStatsVisitorsChildrenCsv(data:any) {
		return this.http.get(apiUrl+'/stats/visitors/children?status='+data.status
										+'&uitgiftepunt='+data.uitgiftepunt
										+'&date_of_birth_month='+data.date_of_birth_month
										+'&csv='+data.csv, { responseType: 'blob' } );
	}
	getStatsSms(date) {
		return this.http.get(apiUrl+'/stats/sms/' + date);
	}
	getStatsVisitorsStats1(data:any) {
		return this.http.post(apiUrl+'/stats/visitors/stats/1', data );
	}
	getStatsVisitorsStats3(data:any) {
		if (data.csv) {
			return this.http.post(apiUrl+'/stats/visitors/stats/3', data, {responseType: 'blob'} );
		}
		else {
			return this.http.post(apiUrl+'/stats/visitors/stats/3', data );
		}
		
	}
	getVVStats1(data) {
		if (data.download) {
			return this.http.post(apiUrl+'/stats/vv/stats/1', data, {responseType: 'blob'});
		}
		else {
			return this.http.post(apiUrl+'/stats/vv/stats/1', data);
		}
		
	}

	getOpvangStats1(data) {
		if (data.download) {
			return this.http.post(apiUrl+'/stats/opvang/stats/1', data, {responseType: 'blob'});
		}
		else {
			return this.http.post(apiUrl+'/stats/opvang/stats/1', data);
		}
		
	}



	// DASHBOARD
	dashboardMyClients(options, options2) {
		return this.http.get(apiUrl+'/stats/dashboard/myclients/' + options + '/' + options2 );
	}
	getDashboardStats1() {
		return this.http.get(apiUrl+'/stats/dashboard/stats/1' );
	}


	// SMS
	sendSms(data) {
		return this.http.post(apiUrl+'/sms/send', data);
	}
	makeSms(visitor_id, appointment_id, template_id) {
		return this.http.get(apiUrl+'/sms/make/' + visitor_id + '/' + appointment_id + '/' + template_id);
	}
	getSmsTemplates() {
		return this.http.get(apiUrl+'/sms/templates');
	}


	// EMAIL
	getEmailBasics(visitor_id) {
		return this.http.get(apiUrl+'/email/basics/' + visitor_id);
	}
	getAllContacts() {
		return this.http.get(apiUrl+'/email/contacts/all');
	}
	newContact(data) {
		return this.http.post(apiUrl+'/email/contacts/new', {data: data});
	}
	postContact(data) {
		return this.http.post(apiUrl+'/email/contacts/post', {data: data});
	}
	emailSend(data) {
		return this.http.post(apiUrl+'/email/send', data);
	}


	// SHELTERS
	getShelters() {
		return this.http.get(apiUrl+'/shelters/all');
	}
	getShelterMatrix(visitor_id: number) {
		return this.http.get(apiUrl+'/shelters/matrix/get/'+ visitor_id);
	}
	downloadShelterMatrix(visitor_id: number) {
		return this.http.get(apiUrl+'/shelters/matrix/get/'+ visitor_id + '/1', { responseType: 'blob' });
	}
	updateShelterMatrix(visitor_id: number, sub, val) {
		return this.http.get(apiUrl+'/shelters/matrix/update/'+ visitor_id + '/' + sub + '/' + val);
	}
	getShelterLocation(location_id: number) {
		return this.http.get(apiUrl+'/shelters/location/get/'+ location_id);
	}
	updateShelterLocation(data:any) {
		return this.http.post(apiUrl+'/shelters/location/update',data);
	}


	// SHELTERS
	getViceversaAll() {
		return this.http.get(apiUrl+'/viceversa/all');
	}
	getViceversaById(visitor_id) {
		return this.http.get(apiUrl+'/viceversa/get/' + visitor_id);
	}


	// SPREEKUUR
	getSpreekuurByDate(data) {
		return this.http.post(apiUrl+'/spreekuur/getByDate', data);
	}
	getSpreekuurStatsByDate(data) {
		if (data.csv) {
			return this.http.post(apiUrl+'/spreekuur/statsGetByDate', data, { responseType: 'blob' });
		}
		else {
			return this.http.post(apiUrl+'/spreekuur/statsGetByDate', data);
		}
	}
	getActivering(data) {
		return this.http.post(apiUrl+'/activering/get', data);
	}
}